import _ from 'lodash';

export default class NewRule {

    static $inject = [];
    constructor() {}

    private params: Array<any> = [];

    isJSON(MyTestStr){
        try {
            let json = JSON.parse(MyTestStr);
            let MyJSON = JSON.stringify(MyTestStr);
            if(typeof(MyTestStr) == 'string' && MyTestStr.length == 0){
                return false;
            }
            return true;
        } catch(e) {
            return false;
        }
    }

    build(ruleData)
    {
        Object.keys(ruleData).map((key) => {
            ruleData[key] = this.isJSON(ruleData[key]) ? JSON.parse(ruleData[key]) : ruleData[key];
        });

        // this will transform all indexes from snake_case to camelCase
        return _.mapKeys(ruleData, (value, key) => _.camelCase(key));
    }

}
