export default class FieldRulesToAdd {

    static $inject = [];
    constructor() {
        return this;
    }

    build(newRule, ruleData, fieldIDToSend, id, selectedRuleKey, fieldNameForRules, fieldRuleId)
    {
       return {
           rule_parameters_to_add: newRule.rules_parameters,
           rule_name: ruleData[selectedRuleKey].rule_name,
           field_id: fieldIDToSend,
           response_data: newRule.responseData,
           custom_response: newRule.customResponse,
           prevent_clearing_value_on_failure: newRule.preventClearingValueOnFailure,
           conditionally_trigger_rule: newRule.conditionallyTriggerRule,
           scan_station_id: id,
           rule_condition_id: selectedRuleKey,
           field_name_for_rules : fieldNameForRules,
           field_rule_id : fieldRuleId
       }
    }

}